import React from 'react'
import styled from 'styled-components'
import videos from './videos'
import VideoModal from '~shared/VideoModal'

const UnstyledVideoPlayer = ({ id, ...props }) => {
    return (
        <VideoModal {...props}>
            {!!videos[id] && <iframe title="Explanatory video" src={videos[id].url} />}
        </VideoModal>
    )
}

const VideoPlayer = styled(UnstyledVideoPlayer)`
    iframe {
        border: none;
        display: block;
        height: 100%;
        width: 100%;
    }
`

export default VideoPlayer
